@import "@/assets/scss/main.scss";

.wrapper {
    margin-bottom: 1rem;
}

section#term {
    display: flex;
    width: 100%;
    flex-direction: column;

    .term {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 80% auto 2%;
        grid-column-gap: 1.5rem;
        height: 3rem;
        align-content: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;
    background: #FFFFFF;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon#down {
        transform: rotate(180deg);
    }

    .term-icons {
        margin-right: 16px;
    }
}
